body{
  font-family: Inter, Helvetica, "sans-serif" !important; 
  /* font-family: Helvetica, "sans-serif" !important;  */
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.overlay{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  opacity: .5;
  z-index: 999999999;
}

.overlay > div{
  position: absolute;
  left: 50%;
  top: 40%;
}

.sucesso{
  color: #11ac4d;;
  font-size: 1rem;
}

.alerta{
  color: #ac1159;;
  font-size: 0.9rem;
}

.warning{
  color: #c7c2c4;;
  font-size: 0.9rem;
}

.mensagem--user{
  margin-left: 25px;
  color: #a4a1a1;
  font-size: 0.8rem;  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 700px){
  .overlay > div{
    position: absolute;
    left: 40%;
    top: 40%;
  }
  .mensagem--user{
    margin-left: 0;    
    margin-top: 20px;    
  }
}