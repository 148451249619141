// .rdt_TableHeader{    
//     overflow: inherit;
//     position: inherit;
//     background-color: #fff;
// }

.form-selects{
    display: flex;
    flex-wrap:nowrap
}

// .sc-bjEwCx,
// .bsxMp{
//     display: none;
// }