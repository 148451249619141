@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,600;1,200&display=swap');


body {
  padding-top: 0px;
  margin-bottom: 20px;
  // background: url(../../assets/images/splin-line.jpg) repeat-x 0px 0px #f8f9fa;  
  //background-color: #f8f9fa !important;
}


///-----------------------------------------------------------------------------------
///-----------------------------------------------------------------------------------
.container-login a{
	text-decoration: none !important;
  color: #666666;
}

a:focus {
	outline: none !important;
}

input {
	outline: none;
	border: none;
}


::placeholder {
  color: #848181 !important;
  font-size: 15px;
}


/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


.limiter {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  font-family: Poppins;
  background-color: #fafafa;
}

.container-login {
  display: flex;  
  flex-wrap: wrap;
  justify-content: center;    
  width: 100%;    
  padding-top: 100px;  
}

.wrap-login {
  width: 850px;
  // height: 465px;
  // background-color: #fbae05;;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:   80px 85px 100px 50px;
  border: #ffffff 10px solid;
}

.prodabit{
  font-size: 12px;  
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
  width: 830px;
  height: 20px;
}

.prodabit > a{
  color: #3f3838;
  text-decoration: none;
}

/*------------------------------------------------------------------
[  ]*/
.login-pic {
  width: 316px;
}

.login-pic img {
  max-width: 100%;
}


/*------------------------------------------------------------------
[  ]*/
.login-form {
  width: 290px;
}

.login-form-title {
  font-weight: 600;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 25px;
}


/*---------------------------------------------*/
.wrap-input {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.login-form .input {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.5;
  color: #595757;
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  padding: 0 30px 0 68px;
}


/*------------------------------------------------------------------
[ Focus ]*/
.focus-input {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87,184,70, 0.8);
}

.input:focus + .focus-input {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

.symbol-input {
  font-size: 15px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input:focus + .focus-input + .symbol-input {
  color: #57b846;
  padding-left: 28px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background: #57b846;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;
}

.login-form-btn:hover {
  background: #333333;
}

.tilt-image{
  width: 360px;
}

.tilt-image img{
  max-width: 100%;
}

.container-form-change-password{
  width: 100%;
  max-width: 450px;
  padding: 30px;
  margin: auto;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 1024px) {
  .wrap-login {
    // padding: 60px 50px 90px 30px;
    padding: 9% 6% 10% 0%;
    margin: 0 7%
  }

  .login-pic {
    width: 35%;
  }

  .login-form {
    width: 46%;
  }

  .tilt-image{
    width: 330px;
  }

  .prodabit{    
    width: 82%;    
    margin: 10px auto;
  }  
}

@media (max-width: 768px) {
  .wrap-login {
    padding: 100px 80px 33px 80px;
  }

  .login-pic {
    display: none;
  }

  .login-form {
    width: 100%;
  }

  .tilt-image{
    width: 100%;
    text-align: center;
  }

  .login-form-btn {
    margin-bottom: 42px;
  }
}

@media (max-width: 576px) {
  .container-login {  
    padding-top: 0px;    
  }

  .tilt-image{    
    margin: 0;
    height: 250px;
  }

  .wrap-login {
    padding: 5px 15px 33px 15px;
    height: 95vh;
    margin: 0;
    border-radius: 0;
  }
}

.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}