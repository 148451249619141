/*
 * Sidebar
 */

 .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }

.sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  &.open {
    margin-left: 0;      
  }
}

.top-sidebar-heading{
  display: flex;
  flex-wrap: nowrap;
  padding: 6px 13px;
  background-color: #6c2a6f !important;
  border-bottom: 1px solid #dc3545 !important;
  min-height: 47px;
}

.sidebar-head-title{
  margin-left: 15px;
  margin-top: 6px;
  color: #fff;
  font-size: 1rem;
}

.sidebar-wrapper .sidebar-heading {
  padding: 0.18rem 1.25rem;
  font-size: 0.80rem;
  text-transform: uppercase;
  background-color: #eff2f5;
}

.sidebar-wrapper .list-group {
  width: 15rem;
}

.nav-link svg{
    height: 1rem;
    margin-bottom: 5px;
}

.nav-link {
    font-weight: 500;
    color: #898989 !important;
  }
  
.nav-link .feather {
margin-right: 4px;
color: #999;
}
  
.nav-link.active {  
color: #34587a !important;
font-weight: 600;  
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
color: inherit;
}

.nav-link:hover {
    background-color: #f4d8e970;
    font-weight: 600;
    color: #6c2a6f !important;    
}

@media (max-width: 768px) {
  .sidebar-wrapper {
    margin-left: -15rem;
    &.open {
      margin-left: 0rem;      
    }
  }  

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.navbar-brand > span{
  font-size: 0.85rem;
  margin-left: 10px;
}

.navbar-brand > img{
  margin-bottom: -13px;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
