

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

.modal-backdrop {
    z-index: 100 !important;
}

.modal {
    background-image: none !important;
    background-color: rgba( 255, 255, 255, .8 );
}

.modal-title {
    font-size: 1rem !important;
}

.modal-header{
    color: #fff;
    background-color: #6c2a6f;
}

.modal-header .btn-close{
    // color: #fff !important;
    background-color: #fff;
}